import PropTypes from "prop-types";
import React from "react";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { INVALID_MESSAGES, LABELS, PLACEHOLDERS } from "_constants/forms";
import { FILE_TYPES, PRODUCT_CODES } from "_constants/products";
import { MONTH_OPTIONS } from "_constants/variables";
import { checkIfFileAllowed, getFileExtension, getFileExtensionsString, validateTrim } from "_helpers";
import { getDayOptions, getYearOptions } from "_helpers/variables";
import { selectFileTypes } from "_store/orders/selector";

import FileField from "components/atomic/molecules/fields/FileField";
import RadioGroupField from "components/atomic/molecules/fields/RadioGroupField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import SwitchField from "components/atomic/molecules/fields/SwitchField";
import TextField from "components/atomic/molecules/fields/TextField";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";
import CardText from "components/layouts/CardText";

const rules = {
  trademarkType: () => ({
    required: "Please choose the trademark type",
  }),
  markDesc: ({ values }) => ({
    required: INVALID_MESSAGES.markDesc[values.trademarkType],
    validate: validateTrim,
  }),
  literalElements: () => ({
    required: "Please provide a description",
    validate: validateTrim,
  }),
  colorsInLogo: () => ({
    required: "Please provide the list of colors",
    validate: validateTrim,
  }),
  logoFile: (fileType, fileExtentions) => ({
    required: "Logo file is not chosen",
    validate: (value) => {
      const selectedFileExt = getFileExtension(value?.name);
      // const isExtensionAllowed = fileType?.allowedExtensions?.includes(selectedFileExt);
      const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, fileType?.allowedExtensions);

      if (!isExtensionAllowed) {
        return "Please select file with correct extension: " + (fileExtentions || "any type");
      }

      if (value?.size && value?.size / 1024 / 1024 > 5) {
        return "File size limit is 5MB";
      }

      return true;
    },
  }),
  ownName: () => ({
    required: "Please provide the name of the living person",
    validate: validateTrim,
  }),
  translation: () => ({
    required: "Please provide the English translation",
    validate: validateTrim,
  }),
  day: () => ({
    required: "Please provide Day",
  }),
  month: () => ({
    required: "Please provide Month",
  }),
  year: () => ({
    required: "Please provide Year",
    validate: (value) => {
      const currentDate = new Date();

      if (currentDate.getFullYear() - value >= 25) {
        return "The year cannot be more than 1 year in the future or more than 25 years in the past.";
      }

      return true;
    },
  }),
};

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  border: none;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  width: 100%;
  padding: 20px 30px;
  overflow-y: unset;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardText = styled(CardText)`
  padding-left: 8px;
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dbe4f0;
`;

const StyledSpace = styled.div`
  height: 16px;
  width: 100%;

  ${({ size }) =>
    size &&
    css`
    height: &{size}px;
  `}
`;

const TrademarkRegistrationBlock = ({ form }) => {
  const values = useWatch({ control: form?.control });

  const fileTypes = useSelector(selectFileTypes);

  const logoFileType = fileTypes?.[PRODUCT_CODES.incStatesRegisterTrademark]?.[FILE_TYPES.logo];
  const logoFileExtensions = getFileExtensionsString(logoFileType?.allowedExtensions);

  const clearFields = (fields) => {
    fields.forEach((field) => {
      form.setValue(field, "");
    });
  };

  return (
    <StyledCard>
      <StyledCardHeader>Register your trademark</StyledCardHeader>
      <StyledCardContent>
        <RadioGroupField
          column
          name="trademarkType"
          label="Select what you are trying to protect:"
          options={[
            {
              label: "Name",
              value: "NAME",
            },
            {
              label: "Slogan",
              value: "SLOGAN",
            },
            {
              label: "Logo",
              value: "LOGO",
            },
          ]}
          control={form.control}
          errors={form.formState.errors}
          rules={rules.trademarkType()}
        />
        <StyledDivider />
        {values.trademarkType === "LOGO" && (
          <>
            <StyledCardText>
              Please provide a complete and accurate description of your logo, including any words that may appear in
              the logo. A description is: ‘a red cat wrapped around a blue outline of a globe’. Leave out the colors if
              you want to protect your logo in all colors.
            </StyledCardText>
            <TextField
              column
              name="literalElements"
              placeholder="Enter the description of your logo"
              control={form.control}
              errors={form.formState.errors}
              rules={rules.literalElements()}
            />
            <StyledCardText>Do you want to be able to protect your logo in a variety of colors?</StyledCardText>
            <SwitchField
              column
              name="logoColoredVariety"
              labelOn="Yes"
              labelOff="No"
              control={form.control}
              errors={form.formState.errors}
            />
            {values.logoColoredVariety && (
              <StyledCardText bold>
                To protect your logo in a variety of colors, your uploaded logo file must be black on a white background
                (no grey scale, a TRUE black and white image).
              </StyledCardText>
            )}
            {!values.logoColoredVariety && (
              <>
                <StyledCardText>A list of the colors that appear in your logo</StyledCardText>
                <TextField
                  column
                  name="colorsInLogo"
                  placeholder="Enter the list of the colors"
                  control={form.control}
                  errors={form.formState.errors}
                  rules={rules.colorsInLogo()}
                />
              </>
            )}
          </>
        )}
        <StyledCardText>{LABELS.markDesc[values.trademarkType]}</StyledCardText>
        <TextField
          column
          name="markDesc"
          placeholder={PLACEHOLDERS.markDesc[values.trademarkType]}
          control={form.control}
          errors={form.formState.errors}
          rules={rules.markDesc({ values })}
        />
        {values.trademarkType === "LOGO" && (
          <FileField
            column
            name="logoFile"
            accept={logoFileExtensions}
            control={form.control}
            errors={form.formState.errors}
            rules={rules.logoFile(logoFileType, logoFileExtensions)}
          />
        )}
        <StyledSpace />
        <SwitchField
          column
          name="isIncludeLivingPersonName"
          label={`
            Does your mark include a living person’s name—even if a stage name or
            pseudonym or could it be perceived to include a person’s name?
          `}
          labelOn="Yes"
          labelOff="No"
          control={form.control}
          errors={form.formState.errors}
        />
        {values.isIncludeLivingPersonName && (
          <>
            <StyledCardText>
              Does your mark include a name (including a pseudonym, nickname or stage name) that is yours?
            </StyledCardText>
            <SwitchField
              column
              name="isIncludeNicknameName"
              labelOn="Yes"
              labelOff="No"
              control={form.control}
              errors={form.formState.errors}
            />
            {values.isIncludeNicknameName && (
              <>
                <StyledCardText>Enter the legal name to whom the mark references</StyledCardText>
                <TextField
                  column
                  name="ownName"
                  placeholder="Enter the legal name"
                  control={form.control}
                  errors={form.formState.errors}
                  rules={rules.ownName()}
                />
              </>
            )}
            {!values.isIncludeNicknameName && (
              <>
                <StyledCardText>
                  Does your mark include the name (including a pseudonym, nickname or stage name) of a living person
                  other than yourself?
                </StyledCardText>
                <SwitchField
                  column
                  name="isOtherLivingName"
                  labelOn="Yes"
                  labelOff="No"
                  control={form.control}
                  errors={form.formState.errors}
                />
                {values.isOtherLivingName && (
                  <>
                    <StyledCardText>What is the name of the living person in your mark?</StyledCardText>
                    <TextField
                      column
                      name="ownName"
                      placeholder="Enter the name of the living person"
                      control={form.control}
                      errors={form.formState.errors}
                      rules={rules.ownName()}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        <StyledSpace />
        <SwitchField
          column
          name="includeTranslationWords"
          label={`
            Does your mark include any words other than English or non-Latin
            characters?
          `}
          labelOn="Yes"
          labelOff="No"
          control={form.control}
          errors={form.formState.errors}
        />
        {values.includeTranslationWords && (
          <>
            <StyledCardText>
              Please provide the English translation or transliteration of any non-Latin characters or non-English words
            </StyledCardText>
            <TextField
              column
              name="translation"
              placeholder="Enter the English translation or transliteration"
              control={form.control}
              errors={form.formState.errors}
              rules={rules.translation()}
            />
          </>
        )}
        <StyledSpace />
        <SwitchField
          column
          name="currentTrademark"
          label="Are you currently using this trademark in your business?"
          labelOn="Yes"
          labelOff="No"
          control={form.control}
          errors={form.formState.errors}
        />
        {!values.currentTrademark && (
          <StyledCardText>
            If you do not have proof now, you can submit proof later after your trademark is submitted to the Trademark
            office. If you do it later, you will need to pay a $100 government fee (for US trademarks).
          </StyledCardText>
        )}
        {values.currentTrademark && (
          <>
            <SelectField
              column
              name="firstUseAnywhereMonth"
              label="First Use Anywhere"
              placeholder="Month"
              options={MONTH_OPTIONS}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.month()}
              clearFields={() => clearFields(["firstUseAnywhereDay"])}
            />
            <SelectField
              column
              name="firstUseAnywhereDay"
              placeholder="Day"
              options={getDayOptions({ month: values.firstUseAnywhereMonth, year: values.firstUseAnywhereYear })}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.day()}
            />
            <SelectField
              column
              name="firstUseAnywhereYear"
              placeholder="Year"
              options={getYearOptions()}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.year()}
              clearFields={() => clearFields(["firstUseAnywhereDay"])}
            />
            <SelectField
              column
              name="firstUseInCommerceMonth"
              label="First Use In Commerce"
              placeholder="Month"
              options={MONTH_OPTIONS}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.month()}
              clearFields={() => clearFields(["firstUseInCommerceDay"])}
            />
            <SelectField
              column
              name="firstUseInCommerceDay"
              placeholder="Day"
              options={getDayOptions({ month: values.firstUseInCommerceMonth, year: values.firstUseInCommerceYear })}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.day()}
            />
            <SelectField
              column
              name="firstUseInCommerceYear"
              placeholder="Year"
              options={getYearOptions()}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.year()}
              clearFields={() => clearFields(["firstUseInCommerceDay"])}
            />
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkRegistrationBlock.propTypes = {
  form: PropTypes.object,
};

export default TrademarkRegistrationBlock;
