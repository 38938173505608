export const MAIN_CATEGORY = "INC_States";

export const PRODUCT_CODES = Object.freeze({
  incStatesAnnualComplianceReport: "INC_States_AnnualComplianceReport",
  incStatesAnnualReport: "INC_States_AnnualReport",
  incStatesAnnualReportStateFee: "INC_States_AnnualReportStateFee",
  incStatesBankingResolution: "INC_States_BankingResolution",
  incStatesBoi: "INC_States_BOI",
  incStatesBusinessLicenses: "INC_States_BusinessLicenses",
  incStatesCertificateOfGoodStanding: "INC_States_CertificateOfGoodStanding",
  incStatesCertifiedCopy: "INC_States_CertifiedCopy",
  incStatesCertifiedCopyStateFee: "INC_States_CertifiedCopyStateFee",
  incStatesDissolution: "INC_States_Dissolution",
  incStatesDissolutionFilingFee: "INC_States_DissolutionFilingFee",
  incStatesEIN: "INC_States_EIN",
  incStatesLLCIncorporation: "INC_States_LLCIncorporation",
  incStatesLLCIncorporationV2: "INC_States_LLCIncorporationV2",
  incStatesLLCKit: "INC_States_LLCKit",
  incStatesLLCTaxFiling: "INC_States_LLC_Tax_filing",
  incStatesMonthlyBookkeeping: "INC_States_Monthly_bookkeeping",
  incStatesOperatingAgreement: "INC_States_OperatingAgreement",
  incStatesPersonalTaxFiling: "INC_States_Personal_Tax_filing",
  incStatesRegisteredAgent: "INC_States_RegisteredAgent",
  incStatesRegisterTrademark: "INC_States_RegisterTrademark",
  incStatesResidentAgent: "INC_States_ResidentAgent",
  incStatesRushProcessing: "INC_States_Rush_Processing",
  incStatesSalesTaxPermit: "INC_States_SalesTaxPermit",
  incStatesSalesTaxPermitStateFee: "INC_States_SalesTaxPermitStateFee",
  incStatesSameDayProcessing: "INC_States_Same_Day_Processing",
  incStatesSameDayProcessingV2: "INC_States_Same_Day_ProcessingV2",
  incStatesSCorp: "INC_States_S_Corp",
  incStatesSoleProprietorTaxFiling: "INC_States_Sole_Proprietor_Tax_filing",
  incStatesStateIncorporationFee: "INC_States_StateIncorporationFee",
  incStatesTrademarkBasicSearch: "INC_States_Trademark_Search_Basic",
  incStatesTrademarkMonitoring: "INC_States_Trademark_Monitoring",
  incStatesUSPTOFilingFee: "INC_States_USPTOFilingFee",
  incStatesCreditCardProcessing: "INC_States_Credit_Card_Processing",
});

export const AMENDMENT_PRODUCTS = Object.freeze({
  incStatesAmendmentBusinessAddress: "INC_States_Amendment_BusinessAddressChange",
  incStatesAmendmentManager: "INC_States_Amendment_ManagerChange",
  incStatesAmendmentOffice: "INC_States_Amendment_OfficeChange",
  incStatesAmendmentRegisteredAgent: "INC_States_Amendment_RegisteredAgentChange",
  incStatesAmendmentBusinessName: "INC_States_Amendment_BusinessNameChange",
  incStatesAmendmentForeignBusinessName: "INC_States_Amendment_ForeignBusinessNameChange",
  incStatesAmendmentAllOtherAmendments: "INC_States_Amendment_AllOtherAmendments",
  incStatesAmendmentTrademarkMinor: "INC_States_Amendment_TrademarkMinor",
  incStatesAmendmentTrademarkIntensive: "INC_States_Amendment_TrademarkIntensive",
});

export const FILE_TYPES = Object.freeze({
  incStateForm: "INC_STATE_FORM",
  mail: "TRADEMARK_MAIL",
  einPDF: "EIN_PDF",
  draftOfApplication: "TRADEMARK_DRAFT_OF_APPLICATION",
  approvalDenialUspto: "TRADEMARK_APPROVAL_DENIAL_USPTO",
  completedRegistrationDocument: "TRADEMARK_COMPLETED_REGISTRATION_DOCUMENT",
  noticeOfAllowance: "TRADEMARK_NOTICE_OF_ALLOWANCE",
  noticeOfAbandonment: "TRADEMARK_NOTICE_OF_ABANDONMENT",
  specimen: "TRADEMARK_SPECIMEN",
  logo: "TRADEMARK_LOGO",
  boiId: "BOI_ID",
});

export const CUSTOM_ACTION_CODES = Object.freeze({
  boiOffer: "BOI_OFFER",
});
