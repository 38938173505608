import PropTypes from "prop-types";
import React from "react";

import { PRODUCT_CODES } from "_constants/products";

import BankingResolutionUpsell from "components/atomic/organisms/modals/upsells/BankingResolutionUpsell";
import CreditCardProcessingUpsell from "components/atomic/organisms/modals/upsells/CreditCardProcessingUpsell";
import EinTaxIdUpsell from "components/atomic/organisms/modals/upsells/EinTaxIdUpsell";
import OperatingAgreementUpsell from "components/atomic/organisms/modals/upsells/OperatingAgreementUpsell";

function UpsellModal({ upsellCode, onCancel }) {
  const upsellProps = {
    upsellCode,
    onCancel,
  };

  switch (upsellCode) {
    case PRODUCT_CODES.incStatesBankingResolution:
      return <BankingResolutionUpsell {...upsellProps} />;

    case PRODUCT_CODES.incStatesOperatingAgreement:
      return <OperatingAgreementUpsell {...upsellProps} />;

    case PRODUCT_CODES.incStatesEIN:
      return <EinTaxIdUpsell {...upsellProps} />;

    case PRODUCT_CODES.incStatesCreditCardProcessing:
      return <CreditCardProcessingUpsell {...upsellProps} />;

    default:
      return null;
  }
}

UpsellModal.propTypes = {
  upsellCode: PropTypes.string,
  onCancel: PropTypes.func,
};

export default UpsellModal;
