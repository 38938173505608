import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { FILE_TYPES, PRODUCT_CODES } from "_constants/products";
import { MONTH_OPTIONS } from "_constants/variables";
import { checkIfFileAllowed, getFileExtension, getFileExtensionsString, handleError, validateTrim } from "_helpers";
import { createDate, getCustomYearOptions, getDayOptions, isLeapYear } from "_helpers/variables";
import { OrdersService } from "_services";
import { selectActiveOrderId, selectEINRegisterProduct, selectFileTypes } from "_store/orders/selector";
import { getOrder, getOrders } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import colors from "components/Theme/colors";
import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import CheckboxField from "components/atomic/molecules/fields/CheckboxField";
import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";
import FileField from "components/atomic/molecules/fields/FileField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormFields = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 12px 4px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f0f0f0;
        border-radius: 12px;
        opacity: 0.5;
      }
    `}
`;

const Text = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }

  ${({ subtext }) =>
    subtext &&
    css`
      font-size: 0.875rem;
    `}

  ${({ textCenter }) =>
    textCenter &&
    css`
      width: 100%;
      text-align: center;
    `}

  ${({ textJustify }) =>
    textJustify &&
    css`
      width: 100%;
      text-align: justify;
    `}

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      align-items: end;
    `}
`;

const FieldsGroup = styled.div``;
const FieldsGroupHeader = styled.div`
  margin: 0 0 8px 8px;
`;
const FieldsGroupContentDate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;

  & > div {
    flex: 1;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 24px;
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

const STEPS = {
  init: "",
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  successWithError: "step-success-with-error",
  error: "step-error",
};

const selectStyles = {
  menuList: (base) => ({ ...base, maxHeight: "140px" }),
  option: (base) => ({
    ...base,
    padding: "4px 12px",
  }),
};

const rules = {
  ssn: (values) => ({
    required: values.willUploadLater ? false : "Social Security Number is a required field",
    validate: (value) => {
      if (values.willUploadLater) {
        return true;
      }

      const pattern = /^\d{3}-\d{2}-\d{4}$/i;
      if (!pattern.test(value)) {
        return "Provide Social Security Number in the required format";
      }

      return true;
    },
  }),
  boiIdFile: (fileType, fileExtentions, values) => ({
    required: values.willUploadLater ? false : "Customer ID file is not chosen",
    validate: (value) => {
      if (values.willUploadLater) {
        return true;
      }

      const selectedFileExt = getFileExtension(value?.name);
      const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, fileType?.allowedExtensions);

      if (!isExtensionAllowed) {
        return "Please select file with correct extension: " + (fileExtentions || "any type");
      }

      if (value?.size && value?.size / 1024 / 1024 > 5) {
        return "File size limit is 5MB";
      }

      return true;
    },
  }),
  day: (values) => ({
    required: values.willUploadLater ? false : "Please provide Day",
    validate: (value) => {
      if (!isLeapYear(values.dateOfBirthYear) && value === "29" && values.dateOfBirthMonth === "2") {
        return 'Incorrect date, "Feb 29" in not leap year';
      }

      return true;
    },
  }),
  month: (values) => ({
    required: values.willUploadLater ? false : "Please provide Month",
  }),
  year: (values) => ({
    required: values.willUploadLater ? false : "Please provide Year",
  }),
  driverLicenceOrID: (values) => ({
    required: values.willUploadLater ? false : "Driver's License / ID Number is a required field",
    validate: validateTrim,
  }),
};

const initValues = {
  boiIdFile: null,
  driverLicenceOrID: "",
  dateOfBirthMonth: "",
  dateOfBirthDay: "",
  dateOfBirthYear: "",
  ssn: "",
  willUploadLater: false,
};

const BoiUploadConfirmationModal = ({ onCancel }) => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues, reValidateMode: "onChange" });
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
  } = form;

  const values = useWatch(form);

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const einProduct = useSelector(selectEINRegisterProduct);
  const fileTypes = useSelector(selectFileTypes);

  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.init);

  const boiFileType = fileTypes?.[PRODUCT_CODES.incStatesBoi]?.[FILE_TYPES.boiId];
  const boiFileExtensions = getFileExtensionsString(boiFileType?.allowedExtensions);

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();

    update && customerId && dispatch(getOrders({ customerId }));
    update && orderId && customerId && dispatch(getOrder({ orderId, customerId }));
  }

  const body = {
    order: { processingOption: "UPSELL", campaignId: 28 },
    products: [
      {
        code: { code: PRODUCT_CODES.incStatesBoi },
      },
    ],
  };

  const bodyWithInfo = {
    order: { processingOption: "UPSELL", campaignId: 28 },
    products: [
      {
        code: { code: PRODUCT_CODES.incStatesBoi },
        dateOfBirth: createDate(values, "dateOfBirth"),
        ssn: values.ssn || null,
        driverLicenceOrID: values.driverLicenceOrID || null,
      },
    ],
  };

  async function createBoiUpsell() {
    try {
      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          setError({
            message: "Uh oh - your card on file has been declined. Please update your payment method to proceed",
          });
          setStep(STEPS.error);
        } else {
          setStep(STEPS.success);
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (error) {
      setStep(STEPS.error);
      setError(handleError(error.message));
    }
  }

  async function createBoiUpsellWithFile() {
    try {
      let newOrderId = null;
      let newProductId = null;

      try {
        const request = await OrdersService.createDelayedUpsell({
          orderId,
          body: bodyWithInfo,
        });

        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          throw new Error("Card Declined");
        }

        newOrderId = request?.data?.order?.id;
        newProductId = request?.data?.products[0]?.id;
      } catch (error) {
        const { message } = handleError(error);

        if (error?.message === "Card Declined") {
          throw new Error(message);
        }

        throw new Error("An error occurred during the order creation process" + "\n" + (message || "Unknown error"));
      }

      try {
        if (!newOrderId || !newProductId) {
          throw new Error("An error occurred during the file upload process" + " – " + "Order or Product ID not found");
        }

        const formData = new FormData();
        formData.append("file", values.boiIdFile);

        const request = await OrdersService.uploadFile({
          orderId: newOrderId,
          productId: newProductId,
          body: formData,
          fileType: "BOI_ID",
        });

        if (request?.status === 200) {
          setStep(STEPS.success);
        } else {
          throw new Error("An error occurred during the file upload process" + " – " + "Unknown error");
        }
      } catch (error) {
        const { message } = handleError(error);
        setStep(STEPS.successWithError);
        setError({ message: message || "Unknown Error" });
      }
    } catch (err) {
      setStep(STEPS.error);
      setError(handleError(err.message));
    }
  }

  const clearFields = (fields, toEmpty = false) => {
    fields.forEach((field) => {
      if (toEmpty) {
        setValue(field, "");
      } else {
        setValue(field, initValues[field]);
      }
    });
  };

  const changeFields = (fields) => {
    const fieldsKeys = Object.keys(fields);

    fieldsKeys.forEach((fieldKey) => {
      setValue(fieldKey, fields[fieldKey]);
    });
  };

  function onSubmit() {
    setStep(STEPS.loading);

    if (values.willUploadLater) {
      createBoiUpsell();
    } else {
      createBoiUpsellWithFile();
    }
  }

  useEffect(() => {
    orderId && setStep(STEPS.start);
  }, [orderId]);

  useEffect(() => {
    if (values.willUploadLater) {
      trigger();
    }
  }, [values.willUploadLater, values.ssn]);

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <StyledHeader closeButton>Beneficial Ownership Information (BOI)</StyledHeader>
          <StyledBody>
            <Row>
              <Text>
                {"We need an acceptable form of ID to submit this report - you may upload this now or later."}
              </Text>
            </Row>
            <Row>
              <Text subtext>
                {
                  "(Accepted identification includes a state-issued driver’s license, a state, local, or tribe-issued ID, a US passport or, if none of the previous forms of identification are available, a foreign passport.)"
                }
              </Text>
            </Row>
            <Row>
              <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <FormFields $disabled={values.willUploadLater}>
                  <FileField
                    column
                    label="Driver's License or ID"
                    name="boiIdFile"
                    control={control}
                    errors={errors}
                    disabled={values.willUploadLater}
                    accept={boiFileExtensions}
                    rules={rules.boiIdFile(boiFileType, boiFileExtensions, values)}
                  />
                  <TextField
                    column
                    label="Driver's License or ID Number"
                    name="driverLicenceOrID"
                    placeholder="Enter Number"
                    control={control}
                    errors={errors}
                    disabled={values.willUploadLater}
                    rules={rules.driverLicenceOrID(values)}
                  />
                  <FieldsGroup>
                    <FieldsGroupHeader>Date of Birth</FieldsGroupHeader>
                    <FieldsGroupContentDate>
                      <SelectField
                        column
                        name="dateOfBirthMonth"
                        placeholder="Month of Birth"
                        styles={selectStyles}
                        options={[{ value: "", label: "Select..." }, ...MONTH_OPTIONS]}
                        control={control}
                        errors={errors}
                        disabled={values.willUploadLater}
                        rules={rules.month(values)}
                        clearFields={() => clearFields(["dateOfBirthDay"], true)}
                      />
                      <SelectField
                        column
                        name="dateOfBirthDay"
                        placeholder="Day of Birth"
                        styles={selectStyles}
                        options={[
                          { value: "", label: "Select..." },
                          ...getDayOptions({ month: values.dateOfBirthMonth, year: values.dateOfBirthYear }),
                        ]}
                        control={control}
                        errors={errors}
                        disabled={values.willUploadLater}
                        rules={rules.day(values)}
                      />
                      <SelectField
                        column
                        name="dateOfBirthYear"
                        placeholder="Year of Birth"
                        styles={selectStyles}
                        options={[
                          { value: "", label: "Select..." },
                          ...getCustomYearOptions({ startYear: 1920, endYear: new Date().getFullYear() }),
                        ]}
                        control={control}
                        errors={errors}
                        disabled={values.willUploadLater}
                        rules={rules.year(values)}
                        changeFields={(value) => {
                          if (!isLeapYear(value) && values.dateOfBirthDay === "29" && values.dateOfBirthMonth === "2") {
                            changeFields({ dateOfBirthDay: "28" });
                          }
                        }}
                      />
                    </FieldsGroupContentDate>
                  </FieldsGroup>
                  {!einProduct && (
                    <CustomMaskField
                      column
                      label="SSN"
                      placeholder="000-00-0000"
                      mask="999-99-9999"
                      name="ssn"
                      control={control}
                      errors={errors}
                      disabled={values.willUploadLater}
                      rules={rules.ssn(values)}
                    />
                  )}
                </FormFields>

                <Buttons>
                  <CheckboxField
                    name="willUploadLater"
                    label="I will upload this later"
                    control={control}
                    errors={errors}
                  />
                  <Button
                    type="submit"
                    variant="success"
                    disabled={Object.keys(errors).length !== 0 && !values.willUploadLater}
                  >
                    Continue
                  </Button>
                </Buttons>
              </StyledForm>
            </Row>
          </StyledBody>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => {}}>
          <StyledHeader>Beneficial Ownership Information (BOI)</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader>Beneficial Ownership Information (BOI)</StyledHeader>
          <StyledBody>
            <Row>Thank you! Your BOI (Beneficial Ownership Information Report) order has successfully been placed.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.successWithError:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader>Beneficial Ownership Information (BOI)</StyledHeader>
          <StyledBody>
            <Row>Thank you! Your BOI (Beneficial Ownership Information Report) order has successfully been placed.</Row>
            <Row>
              <StyledIcon color={colors["cl-text-danger"]} icon="error:alert" size="24px" />
              &nbsp;But an error occurred while uploading the file, so you will need to try to upload the file again.
              The necessary action will appear on Action Required section.
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader>Beneficial Ownership Information (BOI)</StyledHeader>
          <StyledBody>
            <Row>
              <ErrorText>
                <StyledIcon icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

BoiUploadConfirmationModal.propTypes = {
  onCancel: PropTypes.func,
};

export default BoiUploadConfirmationModal;
