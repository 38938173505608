import { config } from "_config";
import { mappingQueryParams } from "_helpers";
import { AxiosClient } from "_services/AxiosClient";

const { ordersApiUrl } = config;

class OrdersService extends AxiosClient {
  constructor(url) {
    super(url);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.getFileFromProduct = this.getFileFromProduct.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.createDelayedUpsell = this.createDelayedUpsell.bind(this);
    this.contactUs = this.contactUs.bind(this);
    this.rerunSubscription = this.rerunSubscription.bind(this);
  }

  cancelSubscription(orderId, productId) {
    const path = `/orders/${orderId}/products/${productId}/cancel-subscription`;
    return this.client.post(path);
  }

  rerunSubscription(orderId, productId) {
    const path = `/orders/${orderId}/products/${productId}/rerun-subscription`;
    return this.client.post(path);
  }

  getFileFromProduct(orderId, productId, fileType) {
    const path = `/orders/${orderId}/products/${productId}/result-PDF${mappingQueryParams({ fileType })}`;
    return this.client.get(path, { responseType: "blob" });
  }

  uploadFile({ orderId, productId, body, fileType }) {
    const path = `/orders/${orderId}/products/${productId}/result-PDF`;

    const params = {
      fileType: "TRADEMARK_SPECIMEN",
    };

    if (fileType) {
      params.fileType = fileType;
    }

    return this.client.post(path, body, { params });
  }

  createDelayedUpsell({ orderId, body }) {
    const path = `/orders/${orderId}/create-delayed-upsale`;
    return this.client.post(path, body);
  }

  contactUs(body) {
    const path = `/contact-us`;
    return this.client.post(path, body);
  }

  dissolveOrder(orderId) {
    const path = `/orders/${orderId}/dissolve`;
    return this.client.post(path);
  }
}

const ServiceInstance = new OrdersService(ordersApiUrl);

export { ServiceInstance as OrdersService };
